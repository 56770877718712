<template>
  <div>
    <wapHeader />
    <div style="padding: 10px;">
      <el-row type="flex" :gutter="8">
        <el-col :span="8">
          <div class="polaroid">
            <img :src="book.coverUrl" style="width: 100%; height: 200px" />
          </div>
        </el-col>
        <el-col :span="16">
          <el-card :body-style="{ padding: '0px' }" style="height: 200px">
            <div class="book-content">
              <h2 class="subtitle">
                {{ book.name }}
              </h2>
              <div>
                <span>Author: </span>
                <span>{{ book.author }}</span>
              </div>
              <div>
                <span>Introduction: </span>
                <span>{{ book.synopsis }}</span>
              </div>
              <el-button v-if="!collected" @click="collect(book.id)" type="primary"><i
                  class="material-icons mdi mdi-library-books"></i> add to
                bookshelf
                <div class="ripple-container"></div>
              </el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-card :body-style="{ padding: '10px 0' }" style="margin-top: 10px;">
        <div slot="header" class="clearfix">
          <!-- <span>Contents</span> -->
          <h2 class="subtitle">
            Contents
          </h2>
          <el-button v-if="isAdmin === 1 && book.userId === memberId" @click="handleAdd()"
            style="float: right;margin-right: 30px;font-size: 20px; padding: 3px 0" type="text">Upload</el-button>
        </div>
        <el-row :gutter="6">
          <el-col :span="8" v-for="content in contents" v-bind:key="content.index" @click.native="reading(content.id)">
            {{ content.name }}
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.polaroid {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.book-content {
  height: 200px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.subtitle {
  margin: 0;
  font-size: 28px;
}

p:hover {
  color: rgb(2, 92, 2);
}

::v-deep .el-card {
  border: none
}

::v-deep .el-card__header {
  padding: 10px;
  color: $theme-color;
}

/* 鼠标移动到链接上 */
</style>

<script>
import wapHeader from './wapHeader.vue';
export default {
  components: {
    wapHeader,
  },
  data() {
    return {
      contents: [
        {
          id: 0,
          name: "",
          fileUrl: "",
        },
      ],
      book: {
        id: 0,
        name: "",
        author: "",
        synopsis: "",
        chapterNum: 0,
        coverUrl: "",
        userId: 0,
      },
      dialog: {
        show: false,
        title: "",
      },
      uploadDialog: {
        show: false,
        title: "",
      },
      formData: {},
      collected: false,
      logined: false,
      memberName: "",
      memberId: "",
      token: "",
      isAdmin: 0,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    handleChange(file, fileList) {
      this.formData.fileList = [...fileList];
    },
    handleRemove(file, fileList) {
      this.formData.fileList = [...fileList];
    },
    //打开新增窗口
    handleAdd() {
      this.formData = {
        id: "",
        fileList: [],
      };
      this.uploadDialog = {
        show: true,
        title: "新增",
        option: "add",
      };
    },
    add() {
      if (this.formData.fileList.length == 0) {
        this.$message({
          message: "文件不可为空",
          type: "error",
          showClose: true,
        });
        return;
      }
      this.$apiFun
        .chapterAdd(this.$route.params.id, this.book.name, this.formData, this.memberId)
        .then((res) => {
          if (res.Code === 0) {
            this.$message({
              message: "添加成功",
              type: "success",
              showClose: true,
            });
            this.init();
            this.uploadDialog.show = false;
          }
          else if (res.Code === 1) {
            this.$message({
              message: "该章节已存在",
              type: "error",
              showClose: true,
            });
          }
        });
    },
    async init() {
      await this.getDetail();
      await this.getContent();
    },
    async getDetail() {
      await this.$apiFun
        .bookGetDetail({
          id: this.$route.params.id,
          user_id: this.memberId,
        })
        .then((res) => {
          if (res.Code === 0) {
            this.book = res.Data.book;
            this.collected = res.Data.collected;
          }
        });
    },
    async getContent() {
      this.contents = [];
      await this.$apiFun
        .bookGetContent({
          book_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.Code === 0) {
            for (var i in res.Data) {
              this.contents.push({
                name: "CHAPTER " + res.Data[i].chapterId,
                fileUrl: res.Data[i].fileUrl,
                id: res.Data[i].chapterId,
              });
            }
          }
        });
      if (this.book.userId === this.memberId && this.isAdmin === 1) {
        await this.$apiFun
          .bookGetUnofficialContent({
            book_id: this.$route.params.id,
          })
          .then((res) => {
            if (res.Code === 0) {
              for (var i in res.Data) {
                this.contents.push({
                  name: "CHAPTER " + res.Data[i].chapterId + " (To Be Reviewed)",
                  fileUrl: res.Data[i].fileUrl,
                  id: res.Data[i].chapterId,
                });
              }
            }
          });
      }
    },
    reading(id) {
      this.$router.push("/wapReading/" + this.book.name + "/" + this.book.id + "/" + id);
    },
    collect(id) {
      if (this.memberId === "") {
        this.$message({
          message: "Please login first",
          type: "error",
          showClose: true,
        });
        return;
      }
      this.$apiFun
        .bookCollect({
          book_id: id,
          user_id: this.memberId,
        }, {
          Authorization: "Bearer " + this.token,
        })
        .then((res) => {
          if (res.Code === 0) {
            this.$message({
              message: "加入成功",
              type: "success",
              showClose: true,
            });
            this.collected = true;
          }
        });
    },
  }
};
</script>