<template>
    <div class="loginDialog">
      <el-dialog :title="tmp_dialog.title" width="35%" :visible.sync="tmp_dialog.show">
        <el-form :model="formData" :rules="rules" label-width="100px">
          <el-form-item label="Phone Number"  label-width="120px" prop="phoneNumber">
            <el-input v-model="formData.phoneNumber" autocomplete="off" style="width: 90%;float: left;"></el-input>
          </el-form-item>
          <el-form-item label="Captcha" label-width="120px" prop="captcha">
            <el-input v-model="formData.captcha" autocomplete="off" style="width: 50%;float: left"></el-input>
            <el-button v-if="buttonShow" @click="getCaptcha" style="float:left;margin-left: 20px;">Get Captcha</el-button>
            <el-button v-if="!buttonShow" style="float:left;margin-left: 20px;" key='new' disabled>{{countSecond}}秒后重试</el-button>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="tmp_dialog.show = false">Cancel</el-button>
          <el-button
            @click="login"
            type="primary"
          >Confirm</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
     
  <script>
  
  export default {
    name: "loginDialog",
    props: ["dialog"],
    data() {
      return {
        tmp_dialog : {
          title: '',
          show: false
        },  
        formData: {
            phoneNumber: '',
            captcha:''
        },
        rules: {
          phoneNumber: [
            { required: true, message: "please input your phone number", trigger: "blur" }
          ]
        },
        buttonShow: true,
        countSecond: '',
        timer: null,
      };
    },
    mounted() {
      this.tmp_dialog = this.dialog
    },
    methods: {
      getCaptcha(){
        if (this.formData.phoneNumber === ''){
            this.$message({
              message: "phone number can't be empty",
              type: "error",
              showClose: true,
            })
            return
        }
        this.$apiFun.getOTP(this.formData.phoneNumber)
        .then((res) => {
            if(res.Code === 0){
                const TIME_COUNT = 60;
                if (!this.timer) {
                    this.countSecond = TIME_COUNT;
                    this.buttonShow = false;
                    this.timer = setInterval(() => {
                        if (this.countSecond > 0 && this.countSecond <= TIME_COUNT) {
                            this.countSecond--;
                        } else {
                            this.buttonShow = true;
                            clearInterval(this.timer);
                            this.timer = null;
                        }
                    }, 1000)
                }
            }
        })
      },
      login(){
        if (this.formData.phoneNumber === '' || this.formData.captcha === ''){
            this.$message({
              message: "phone number and captcha can't be empty",
              type: "error",
              showClose: true,
            })
            return
        }
        this.$apiFun.login({
            phoneNumber: this.formData.phoneNumber,
            captcha: this.formData.captcha
        })
        .then((res) => {
            if(res.Code === 0){
                let userInfo = {
                    isLogin: true,
                    memberId: res.Data.id,
                    memberName: res.Data.name,
                    token: res.Data.token,
                    isAdmin:res.Data.isAdmin,
                };
                localStorage.setItem("userInfo", JSON.stringify(userInfo));
                // this.$store.state.userInfo = userInfo

                this.$message({
                    message: "login success",
                    type: 'success',
                    showClose: true,
                });
                this.tmp_dialog.show = false
                this.$emit("callback");
            }else if(res.Code === 1) {
                this.$message({
                    message: "captcha has expired",
                    type: "error",
                    showClose: true,
                })
            }else if(res.Code === 2){
                this.$message({
                    message: "captcha vaildation error",
                    type: "error",
                    showClose: true,
                })
            }
        })
      }
    },
    watch: {
      dialog: {
        handler(newV){
          this.tmp_dialog = JSON.parse(JSON.stringify(newV));
          this.formData = {
            phoneNumber: '',
            captcha: ''
          }
        },
        deep:true
      },
    }
  };
  </script>
  <style lang="scss" scoped>
  .loginDialog {
    /*去除upload组件过渡效果*/
    ::v-deep .el-upload-list__item {
      transition: none !important;
    }
  }
  </style>