import https from './http.js';
	
let apiFun = {};

/* book上传 */
apiFun.bookAdd = function(memberId,data,fileList) {
    const formData = new FormData()
    formData.append('user_id',memberId)
    formData.append('name',data.name)
    formData.append('author',data.author)
    formData.append('synopsis',data.synopsis)
    if (fileList.length!=0) {
        formData.append('image', fileList[0].raw) //添加文件对象
    }
    return https.post('/admin/book/add', formData)
}

/* book获取列表 */
apiFun.bookGetList = function(params) {
    return https.get('/service/book/list', params)
}

/* chapter获取列表 */
apiFun.bookGetContent = function(params) {
    return https.get('/service/book/chapter/list', params)
}

/* 待审核chapter获取列表 */
apiFun.bookGetUnofficialContent = function(params) {
    return https.get('/service/book/unofficial_chapter/list', params)
}

/* book获取细节 */
apiFun.bookGetDetail = function(params) {
    return https.get('/service/book/detail', params)
}

/* book加入书架 */
apiFun.bookCollect = function(params,headers) {
    return https.get('/auth/service/collect/book', params,headers)
}

/* chapter加载 */
apiFun.chapter = function(params) {
    var url = '/book/'+params.book_name+'/'+params.chapter_id
    return https.get(url)
}

/* chapterLable加载 */
apiFun.getChapterLabel = function(params) {
    return https.get('/service/book/chapter/label', params)
}

/* picture上传 */
apiFun.pictureAdd = function(memberId,data,fileList) {
    const formData = new FormData()
    formData.append('user_id',memberId)
    formData.append('name',data.name)
    formData.append('author',data.author)
    formData.append('synopsis',data.synopsis)
    formData.append('image', fileList[0].raw) //添加文件对象
    return https.post('/admin/picture/add', formData)
}

/* picture获取列表 */
apiFun.pictureGetList = function(params) {
    return https.get('/service/picture/list', params)
}

/* picture获取细节 */
apiFun.pictureGetDetail = function(params) {
    return https.get('/service/picture/detail', params)
}

/* picture加入我的 */
apiFun.pictureCollect = function(params,headers) {
    return https.get('/auth/service/collect/picture', params,headers)
}

/* video上传 */
apiFun.videoAdd = function(memberId,data,fileList) {
    const formData = new FormData()
    formData.append('user_id',memberId)
    formData.append('name',data.name)
    formData.append('author',data.author)
    formData.append('synopsis',data.synopsis)
    formData.append('video', fileList[0].raw) //添加文件对象
    return https.post('/admin/video/add', formData)
}

/* video添加封面 */
apiFun.videoCoverAdd = function(name,fileList) {
    const formData = new FormData()
    formData.append('video_name',name)
    formData.append('image', fileList[0].raw) //添加文件对象
    return https.post('/admin/video/cover/add', formData)
}

/* video获取列表 */
apiFun.videoGetList = function(params) {
    return https.get('/service/video/list', params)
}

/* video获取细节 */
apiFun.videoGetDetail = function(params) {
    return https.get('/service/video/detail', params)
}

/* video加入我的 */
apiFun.videoCollect = function(params,headers) {
    return https.get('/auth/service/collect/video', params,headers)
}

/* 我的book */
apiFun.myBook = function(params,headers) {
    return https.get('/auth/service/mine/book', params,headers)
}

/* 我的picture */
apiFun.myPicture = function(params,headers) {
    return https.get('/auth/service/mine/picture', params,headers)
}

/* 我的video */
apiFun.myVideo = function(params,headers) {
    return https.get('/auth/service/mine/video', params,headers)
}

/* 获取OTP验证码 */
apiFun.getOTP = function(phoneNumber) {
    const formData = new FormData()
    formData.append('phone_number',phoneNumber)
    return https.post('/service/user/OTP', formData)
}

/* login */
apiFun.login = function(data) {
    const formData = new FormData()
    formData.append('phone_number',data.phoneNumber)
    formData.append('captcha',data.captcha)
    return https.post('/service/user/login', formData)
}

/* 上传的book列表 */
apiFun.uploadBookGetList = function(params,headers) {
    return https.get('/auth/service/uploaded/book', params,headers)
}

/* chapter上传 */
apiFun.chapterAdd = function(bookId,bookName,data,memberId) {
    const formData = new FormData()
    formData.append('user_id',memberId)
    formData.append('book_id',bookId)
    formData.append('book_name',bookName)
    formData.append('chapter_id',data.id)
    formData.append('file', data.fileList[0].raw) //添加文件对象
    return https.post('/admin/book/chapter/upload', formData)
}

/* 上传的picture列表 */
apiFun.uploadPictureGetList = function(params,headers) {
    return https.get('/auth/service/uploaded/picture', params,headers)
}

/* 上传的video列表 */
apiFun.uploadVideoGetList = function(params,headers) {
    return https.get('/auth/service/uploaded/video', params,headers)
}

// banner 列表
apiFun.getBannerList = () => {
    return https.get('/service/banner/list');
}

// 热门搜索 - book
apiFun.getBookHotSearch = () => {
    return https.get('/service/book/hot/get');
}

// 热门搜索 - picture
apiFun.getPictureHotSearch = () => {
    return https.get('/service/picture/hot/get');
}

// 热门搜索 - video
apiFun.getVideoHotSearch = () => {
    return https.get('/service/video/hot/get');
}

export default apiFun;

