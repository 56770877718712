<template>
  <div>
    <!-- 导航栏 -->
    <header id="header">
      <div class="navbar-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav class="navbar navbar-expand-lg">
                <div class="logo">
                  <img src="../../static/favicon.jpeg" alt="">
                </div>
                <div
                  class="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  <ul id="nav" class="navbar-nav ml-auto">
                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToHome"
                        >Home <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToBook"
                        >Books <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToPicture"
                        >Pictures
                        <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToVideo"
                        >Videos <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>
                  </ul>
                </div>

                <!-- 登录按钮 -->
                <div v-if="!logined" class="search-icon">
                  <span class="open-search">
                    <i class="mdi btn btn-common" @click="login">Login</i>
                  </span>
                </div>
                <div v-if="logined" style="margin-left: 20px">
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      {{ memberName
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="1"
                        >My BookShelf</el-dropdown-item
                      >
                      <el-dropdown-item command="2"
                        >My Gallery</el-dropdown-item
                      >
                      <el-dropdown-item command="3"
                        >My VideoLibrary</el-dropdown-item
                      >
                      <el-dropdown-item command="4" v-if="isAdmin === 1"
                        >My Upload</el-dropdown-item
                      >
                      <el-dropdown-item command="5">Logout</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section class="page-title-section section-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title-center">
              <div class="title-middle">
                <!-- <h2 class="page-tagline">Material Blog</h2> -->
                <h1 class="page-title">Book Details</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <el-row type="flex" justify="center">
      <el-col :span="6">
        <div class="polaroid">
          <img :src="book.coverUrl" style="width: 100%; height: 500px" />
        </div>
      </el-col>
      <el-col :span="11">
        <el-card
          :body-style="{ padding: '0px' }"
          style="margin: 25px; height: 500px"
        >
          <h2
            class="subtitle"
            style="margin-left: 25px; margin-bottom: 20px; font-size: 30px"
          >
            {{ book.name }}
          </h2>
          <div style="margin-left: 25px; margin-top: 10px; font-size: 20px">
            <span>Author: </span>
            <span>{{ book.author }}</span>
          </div>
          <div style="margin-left: 25px; margin-top: 10px; font-size: 20px">
            <span>Introduction: </span>
            <span>{{ book.synopsis }}</span>
          </div>
          <a
            v-if="!collected"
            href="javascript:void(0)"
            @click="collect(book.id)"
            class="animated4 btn btn-common"
            data-ripple-color="#000"
            style="margin-top: 300px; margin-left: 200px"
            ><i class="material-icons mdi mdi-library-books"></i> add to
            bookshelf
            <div class="ripple-container"></div
          ></a>
        </el-card>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="17" style="float: right; margin-bottom: 30px">
        <el-card
          :body-style="{ padding: '0px' }"
          style="margin-left: 0px; margin-right: 25px"
        >
          <div slot="header" class="clearfix">
            <!-- <span>Contents</span> -->
                      <h2 
            style="margin-left:55px;  font-size: 30px;display: inline; color:#5166d8;"
          >
            Contents
          </h2>
            <el-button v-if="isAdmin===1&&book.userId===memberId" @click="handleAdd()" style="float: right;margin-right: 30px;font-size: 20px; padding: 3px 0" type="text"
              >Upload</el-button
            >
          </div>
          <div style="margin-left: 80px; margin-top: 10px; font-size: 20px">
            <p
              v-for="content in contents"
              v-bind:key="content.index"
              @click="reading(content.id)"
            >
              {{ content.name }}
            </p>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <loginDialog :dialog="dialog" @callback="getMemberInfo"></loginDialog>
    <el-dialog
      :title="uploadDialog.title"
      width="45%"
      :visible.sync="uploadDialog.show"
      center
    >
      <el-form
        ref="ref_form_chapterManage"
        :model="formData"
        label-width="100px"
      >
        <el-form-item label="编号" prop="id">
          <el-input
            v-model="formData.id"
            autocomplete="off"
            placeholder="可选填"
            style="width: 90%"
          ></el-input>
        </el-form-item>
        <el-form-item label="文件" prop="file">
          <el-upload
            action=""
            accept=".txt"
            :limit="1"
            :auto-upload="false"
            :file-list="formData.fileList"
            :on-change="handleChange"
            :on-remove="handleRemove"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传txt文件</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="uploadDialog.show = false">取 消</el-button>
        <el-button @click="add()" type="primary">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<style src="../assets/css/bootstrap-4.5.0.min.css"  scoped></style>
<style src="../assets/css/materialdesignicons.min.css"  scoped></style>
<style src="../assets/css/material.min.css"  scoped></style>
<style src="../assets/css/ripples.min.css"  scoped></style>

<style src="../assets/css/magnific-popup.css"  scoped></style>
<style src="../assets/css/animate.css"  scoped></style>
<style src="../assets/css/style.css"  scoped></style>
<style src="../assets/css/responsive.css"  scoped></style>
<style src="../assets/css/colors/indigo.css"  scoped></style>

<style scoped>
.logo {
    width:60px;
    height:60px;
    margin-right: 20px;
}
.logo img  {
    width: 100%;
    height:100%;
}
div.polaroid {
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
  margin-top: 25px;
}
p:hover {
  color: rgb(2, 92, 2);
} /* 鼠标移动到链接上 */
</style>

<script>
import loginDialog from "./loginDialog.vue";
export default {
  components: { loginDialog },
  data() {
    return {
      contents: [
        {
          id: 0,
          name: "",
          fileUrl: "",
        },
      ],
      book: {
        id: 0,
        name: "",
        author: "",
        synopsis: "",
        chapterNum: 0,
        coverUrl: "",
        userId: 0,
      },
      dialog: {
        show: false,
        title: "",
      },
      uploadDialog: {
        show: false,
        title: "",
      },
      formData: {},
      collected: false,
      logined: false,
      memberName: "",
      memberId: "",
      token: "",
      isAdmin: 0,
    };
  },
  mounted() {
    this.getMemberInfo();
    this.init();
  },
  methods: {
    handleChange(file, fileList) {
      this.formData.fileList = [...fileList];
    },
    handleRemove(file, fileList) {
      this.formData.fileList = [...fileList];
    },
    //打开新增窗口
    handleAdd() {
      this.formData = {
        id: "",
        fileList: [],
      };
      this.uploadDialog = {
        show: true,
        title: "新增",
        option: "add",
      };
    },
    add() {
      if (this.formData.fileList.length == 0) {
        this.$message({
          message: "文件不可为空",
          type: "error",
          showClose: true,
        });
        return;
      }
      this.$apiFun
        .chapterAdd(
          this.$route.params.id,
          this.book.name,
          this.formData,
          this.memberId
        )
        .then((res) => {
          if (res.Code === 0) {
            this.$message({
              message: "添加成功",
              type: "success",
              showClose: true,
            });
            this.init();
            this.uploadDialog.show = false;
          } else if (res.Code === 1) {
            this.$message({
              message: "该章节已存在",
              type: "error",
              showClose: true,
            });
          }
        });
    },
    async init() {
      await this.getDetail();
      await this.getContent();
    },
    async getDetail() {
      await this.$apiFun
        .bookGetDetail({
          id: this.$route.params.id,
          user_id: this.memberId,
        })
        .then((res) => {
          if (res.Code === 0) {
            this.book = res.Data.book;
            this.collected = res.Data.collected;
          }
        });
    },
    async getContent() {
      this.contents = [];
      await this.$apiFun
        .bookGetContent({
          book_id: this.$route.params.id,
        })
        .then((res) => {
          if (res.Code === 0) {
            for (var i in res.Data) {
              this.contents.push({
                name: "CHAPTER " + res.Data[i].chapterId,
                fileUrl: res.Data[i].fileUrl,
                id: res.Data[i].chapterId,
              });
            }
          }
        });
      if (this.book.userId === this.memberId && this.isAdmin === 1) {
        await this.$apiFun
          .bookGetUnofficialContent({
            book_id: this.$route.params.id,
          })
          .then((res) => {
            if (res.Code === 0) {
              for (var i in res.Data) {
                this.contents.push({
                  name:
                    "CHAPTER " + res.Data[i].chapterId + " (To Be Reviewed)",
                  fileUrl: res.Data[i].fileUrl,
                  id: res.Data[i].chapterId,
                });
              }
            }
          });
      }
    },
    reading(id) {
      this.$router.push(
        "/reading/" + this.book.name + "/" + this.book.id + "/" + id
      );
    },
    collect(id) {
      if (this.memberId === "") {
        this.$message({
          message: "Please login first",
          type: "error",
          showClose: true,
        });
        return;
      }
      this.$apiFun
        .bookCollect(
          {
            book_id: id,
            user_id: this.memberId,
          },
          {
            Authorization: "Bearer " + this.token,
          }
        )
        .then((res) => {
          if (res.Code === 0) {
            this.$message({
              message: "加入成功",
              type: "success",
              showClose: true,
            });
            this.collected = true;
          }
        });
    },
    goToHome() {
      this.$router.push("/home");
    },
    goToBook() {
      this.$router.push("/book");
    },
    goToPicture() {
      this.$router.push("/picture");
    },
    goToVideo() {
      this.$router.push("/video");
    },
    login() {
      this.dialog = {
        show: true,
        title: "Login",
      };
    },
    getMemberInfo() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo != null && userInfo.isLogin) {
        this.logined = true;
        this.memberName = userInfo.memberName;
        this.memberId = userInfo.memberId;
        this.token = userInfo.token;
        this.isAdmin = userInfo.isAdmin;
      }
    },
    logout() {
      this.logined = false;
      this.memberName = "";
      this.memberId = "";
      this.token = "";
      localStorage.clear();
      setTimeout(() => {
        this.$router.push("/home"); //退出登录后2秒后跳转至首页
      }, 500);
      //加()=>{},()=>{} 可解决路由重复后台报错问题
    },
    handleCommand(command) {
      if (command === "1") {
        this.$router.push("/myBook");
      } else if (command === "2") {
        this.$router.push("/myPicture");
      } else if (command === "3") {
        this.$router.push("/myVideo");
      } else if (command === "4") {
        this.$router.push("/myUpload");
      } else {
        this.logout();
      }
    },
  },
};
</script>