<template>
  <div style="overflow-y: auto;">
    <wapHeader />
    <el-row type="flex" justify="center">
      <el-col :span="23">
        <img v-for="url in picture.fileUrl.split(',')" preview="1" :src="url" style="width: 100%; height: 60vh">
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="23">
        <el-card :body-style="{ padding: '10px' }">
          <h2 class="subtitle" style="margin: 10px; font-size: 24px">
            {{ picture.name }}
          </h2>
          <div style="margin-left: 10px; margin-top: 10px; font-size: 16px">
            <span>Author: </span>
            <span>{{ picture.author }}</span>
          </div>
          <div style="margin-left: 10px; margin-top: 10px; font-size: 16px">
            <span>Introduction: </span>
            <span>{{ picture.synopsis }}</span>
          </div>
          <el-button v-if="!collected" type="primary" style="margin-top: 10px;" @click="collect(picture.id)">add to picture library</el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import wapHeader from './wapHeader.vue';
export default {
  components: {
    wapHeader
  },
  data() {
    return {
      picture: {
        id: 0,
        name: '',
        author: '',
        synopsis: '',
        fileUrl: '',
      },
      collected: false
    }
  },
  methods: {
    getDetail() {
      this.$apiFun.pictureGetDetail({
        id: this.$route.params.id,
        user_id: this.memberId
      }).then((res) => {
        if (res.Code === 0) {
          this.picture = res.Data.picture
          this.collected = res.Data.collected
        }
      })
    },
  },
  mounted() {
    this.getDetail()
  }
}
</script>
<style scoped></style>