<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
    if (this._isMobile()) {
      if (this.$route.path === '/wapHome') return;
      // 手机端
      if (!this.$route.path.includes('wap')) this.$router.replace("/wapHome");
    } else {
      console.log(this.$route.path)
      if (this.$route.path === '/home') return;
      // pc端
      if (this.$route.path.includes('wap')) this.$router.replace("/home");
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
      );
      return flag;
    },
  }
}
</script>

<style>
#app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: #23421b;
  color: #fff;
}
</style>
