<template>
  <div style="overflow-y: auto;">
    <wapHeader />
    <el-row type="flex" justify="center">
      <el-col :span="23">
        <video width="100%" controls style="margin-top:10px;max-height: 60vh;" :src="video.fileUrl" autoplay
          class="video">
          <!-- <source :src="video.fileUrl" type="video/mp4" /> -->
        </video>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="23">
        <el-card :body-style="{ padding: '10px' }">
          <h2 class="subtitle" style="margin: 10px; font-size: 24px">
            {{ video.name }}
          </h2>
          <div style="margin-left: 10px; margin-top: 10px; font-size: 16px">
            <span>Author: </span>
            <span>{{ video.author }}</span>
          </div>
          <div style="margin-left: 10px; margin-top: 10px; font-size: 16px">
            <span>Introduction: </span>
            <span>{{ video.synopsis }}</span>
          </div>
          <el-button v-if="!collected" type="primary" style="margin-top: 10px;" @click="collect(video.id)">add to video library</el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import wapHeader from './wapHeader.vue';
export default {
  components: {
    wapHeader
  },
  data() {
    return {
      video: {
        id: 0,
        name: '',
        author: '',
        synopsis: '',
        fileUrl: '',
      },
      collected: false
    }
  },
  methods: {
    getDetail() {
      this.$apiFun.videoGetDetail({
        id: this.$route.params.id,
        user_id: this.memberId
      }).then((res) => {
        if (res.Code === 0) {
          this.video = res.Data.video
          this.collected = res.Data.collected
        }
      })
    },
  },
  mounted() {
    this.getDetail()
  }
}
</script>
<style scoped></style>