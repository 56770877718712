import { render, staticRenderFns } from "./myBook.vue?vue&type=template&id=df20cbac&scoped=true&"
import script from "./myBook.vue?vue&type=script&lang=js&"
export * from "./myBook.vue?vue&type=script&lang=js&"
import style0 from "../assets/css/bootstrap-4.5.0.min.css?vue&type=style&index=0&id=df20cbac&prod&scoped=true&lang=css&"
import style1 from "../assets/css/materialdesignicons.min.css?vue&type=style&index=1&id=df20cbac&prod&scoped=true&lang=css&"
import style2 from "../assets/css/material.min.css?vue&type=style&index=2&id=df20cbac&prod&scoped=true&lang=css&"
import style3 from "../assets/css/ripples.min.css?vue&type=style&index=3&id=df20cbac&prod&scoped=true&lang=css&"
import style4 from "../assets/css/magnific-popup.css?vue&type=style&index=4&id=df20cbac&prod&scoped=true&lang=css&"
import style5 from "../assets/css/animate.css?vue&type=style&index=5&id=df20cbac&prod&scoped=true&lang=css&"
import style6 from "../assets/css/style.css?vue&type=style&index=6&id=df20cbac&prod&scoped=true&lang=css&"
import style7 from "../assets/css/responsive.css?vue&type=style&index=7&id=df20cbac&prod&scoped=true&lang=css&"
import style8 from "../assets/css/colors/indigo.css?vue&type=style&index=8&id=df20cbac&prod&scoped=true&lang=css&"
import style9 from "./myBook.vue?vue&type=style&index=9&id=df20cbac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df20cbac",
  null
  
)

export default component.exports