<template>
  <div>
    <!-- 导航栏 -->
    <header id="header">
      <div class="navbar-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav class="navbar navbar-expand-lg">
                <div class="logo">
                  <img src="../../static/favicon.jpeg" alt="">
                </div>
                <div
                  class="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  <ul id="nav" class="navbar-nav ml-auto">
                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToHome"
                        >Home <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToBook"
                        >Books <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToPicture"
                        >Pictures
                        <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToVideo"
                        >Videos <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>
                  </ul>
                </div>

                <!-- 登录按钮 -->
                <div v-if="!logined" class="search-icon">
                  <span class="open-search">
                    <i class="mdi btn btn-common" @click="login">Login</i>
                  </span>
                </div>
                <div v-if="logined" style="margin-left:20px">
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      {{ memberName }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="1">My BookShelf</el-dropdown-item>
                      <el-dropdown-item command="2">My Gallery</el-dropdown-item>
                      <el-dropdown-item command="3">My VideoLibrary</el-dropdown-item>
                      <el-dropdown-item command="4" v-if="isAdmin===1">My Upload</el-dropdown-item>
                      <el-dropdown-item command="5">Logout</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>

    <section class="page-title-section section-padding">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="title-center">
              <div class="title-middle">
                <!-- <h2 class="page-tagline">Material Blog</h2> -->
                <h1 class="page-title">Picture Details</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <el-row type="flex" justify="center">
      <el-col :span="7">
        <div v-if="!collected">
          <div class="polaroid">
            <img
              v-for="url in picture.fileUrl.split(',')"
              :src="url"
              style="width: 100%; height: 500px;margin-top: 10px;"
            />
          </div>
        </div>
        <div v-if="collected">
          <div class="polaroid">
            <img
              v-for="url in picture.fileUrl.split(',')"
              :src="url"
              style="width: 100%; height: 500px"
            />
          </div>
        </div>
        </el-col>
      <el-col :span="11">
        <el-card
          :body-style="{ padding: '0px' }"
          style="margin: 25px; height: 240px; padding: 10px;"
        >
          <h2
            class="subtitle"
            style="margin-left: 25px; margin-bottom: 20px; font-size: 30px"
          >
            {{picture.name}}
          </h2>
          <div style="margin-left: 25px; margin-top: 10px; font-size: 20px">
            <span>Author: </span>
            <span>{{picture.author}}</span>
          </div>
          <div style="margin-left: 25px; margin-top: 10px; font-size: 20px">
            <span>Introduction: </span>
            <span>{{picture.synopsis}}</span>
          </div>
          <div style="margin-left: 25px; margin-top: 10px; font-size: 20px">
            <a href="javascript:void(0)" @click="collect(picture.id)" class="animated4 btn btn-common" data-ripple-color="#000" style="float:left"><i class="material-icons mdi mdi-library-books"></i> add to gallery<div class="ripple-container"></div></a>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <loginDialog :dialog="dialog" @callback="getMemberInfo"></loginDialog>
  </div>
</template>


<style src="../assets/css/bootstrap-4.5.0.min.css"  scoped></style>
<style src="../assets/css/materialdesignicons.min.css"  scoped></style>
<style src="../assets/css/material.min.css"  scoped></style>
<style src="../assets/css/ripples.min.css"  scoped></style>

<style src="../assets/css/magnific-popup.css"  scoped></style>
<style src="../assets/css/animate.css"  scoped></style>
<style src="../assets/css/style.css"  scoped></style>
<style src="../assets/css/responsive.css"  scoped></style>
<style src="../assets/css/colors/indigo.css"  scoped></style>

<style scoped>
.logo {
    width:60px;
    height:60px;
    margin-right: 20px;
}
.logo img  {
    width: 100%;
    height:100%;
}
div.polaroid {
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 25px;
  margin-top: 25px;
}
</style>

<script>
import loginDialog from "./loginDialog.vue";
export default {
  components: {loginDialog},
  data(){
    return{
      contents: [{ 
        chapterId:0
      }],
      picture : {
        id: 0,
        name: '',
        author:'',
        synopsis: '',
        fileUrl:'',
      },
      dialog: {
        show: false,
        title: ""
      },
      collected: false,
      logined: false,
      memberName: '',
      memberId: '',
      token: '',
      isAdmin:0
    }
  },
  mounted() { 
    this.getMemberInfo();
    this.getDetail();
  },
  methods: {
    getDetail(){
      this.$apiFun.pictureGetDetail({
        id: this.$route.params.id,
        user_id:this.memberId
      }).then((res) => {
        if(res.Code === 0){
          this.picture = res.Data.picture
          this.collected = res.Data.collected
        }
      })
    },
    collect(id){
      if (this.memberId === "") {
        this.$message({
            message: "Please login first",
            type: "error",
            showClose: true,
        })
        return
      }
      this.$apiFun.pictureCollect({
        picture_id:id,
        user_id:this.memberId
      },{
        Authorization:"Bearer "+ this.token
      }).then((res) => {
        if(res.Code === 0){
          this.$message({
              message: "加入成功",
              type: 'success',
              showClose: true,
          });
          this.collected = true
        }
      })
    },
    goToHome(){
      this.$router.push("/home");
    },
    goToBook(){
      this.$router.push("/book");
    },
    goToPicture(){
      this.$router.push("/picture");
    },
    goToVideo(){
      this.$router.push("/video");
    },
    login(){
      this.dialog = {
        show: true,
        title: "Login",
      }
    },
    getMemberInfo(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if(userInfo != null && userInfo.isLogin){
        this.logined = true
        this.memberName = userInfo.memberName
        this.memberId = userInfo.memberId
        this.token = userInfo.token
        this.isAdmin = userInfo.isAdmin
      }
    },
    logout() {
      this.logined = false;
      this.memberName = '';
      this.memberId = '';
      this.token = '';
      localStorage.clear();
      setTimeout(() => {
        this.$router.push("/home");//退出登录后2秒后跳转至首页
      }, 500)
      //加()=>{},()=>{} 可解决路由重复后台报错问题
    },
    handleCommand(command) {
      if(command === "1"){
        this.$router.push("/myBook");
      }else if(command === "2"){
        this.$router.push("/myPicture");
      }else if(command === "3"){
        this.$router.push("/myVideo");
      }else if(command === "4"){
        this.$router.push("/myUpload");
      }else {
        this.logout()
      }
    }
  }
}
</script>