<template>
  <div class="header">
    <div class="logo" @click="goHome">
      <img class="logo-img" src="../../../static/favicon.png" alt="" width="60px">
    </div>
    <div>
      <i style="font-size: 24px; margin-right: 10px; cursor: pointer;vertical-align: middle;" class="el-icon-search"
        @click="handleSearch"></i>
      <!-- <el-button type="info" round>register</el-button> -->
      <el-button v-if="!userInfo" type="success" round @click="handleLogin">login</el-button>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      userInfo: null,
    }
  },
  methods: {
    handleLogin() {
      this.$router.push('/wapLogin');
    },
    handleSearch() {
      this.$router.push('/wapSearch');
    },
    goHome() {
      this.$router.push('/wapHome');
    }
  },
  mounted() {
    this.data.userInfo = localStorage.getItem('userInfo')
  }
}
</script>
<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}
</style>