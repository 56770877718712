<template>
  <div class="search">
    <el-input placeholder="please input..." v-model="searchValue" class="input-with-search">
      <el-select v-model="searchType" slot="prepend" placeholder="请选择" @change="handleTypeChange">
        <el-option label="Books" value="book"></el-option>
        <el-option label="Pictures" value="picture"></el-option>
        <el-option label="Videos" value="video"></el-option>
      </el-select>
      <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
    </el-input>
    <div style="margin-top: 10px;">
      <el-tag v-for="(v, i) in searchHistory" :style="i !== 0 && 'margin-left: 10px'"
        @click="handleSearchHistory(v.value, v.type)">{{ v.value }}</el-tag>
    </div>
    <div>
      <h2 style="margin: 10px 0;color: orange">{{ hotText }}</h2>
      <el-tag v-for="(v, i) in hotSearch" :style="i !== 0 && 'margin-left: 10px'" @click="handleSearchHistory(v)">{{ v
      }}</el-tag>
    </div>
    <h3 v-if="hasSearch && !list?.length">
      No result match this search.
    </h3>
    <div v-else>
      <el-card shadow="hover" @click.native="goToDetail(item.id)" style="margin-top: 10px;" v-for="item in list">
        <div style="display: flex;">
          <img :src="item.coverUrl" width="60px" height="80px" alt="">
          <div style="margin-left: 10px;">
            <h3>{{ item.name }}</h3>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
     
<script>
export default {
  data() {
    return {
      searchValue: '',
      searchType: 'book',
      hasSearch: false,
      pageNum: 1,
      list: [],
      searchHistory: [],
      hotSearch: [],
      hotText: 'HOT BOOKS',
    };
  },
  mounted() {
    this.searchHistory = localStorage.getItem('searchHistory') ? JSON.parse(localStorage.getItem('searchHistory')) : [];
    this.$apiFun.getBookHotSearch().then(res => {
      if (res.Code == 0) {
        this.hotSearch = res.Data;
      }
    })
  },
  methods: {
    search() {
      this.searchHistory.unshift({ type: this.searchType, value: this.searchValue });
      this.searchHistory = this.searchHistory.filter((_, i) => i < 5);
      localStorage.setItem('searchHistory', JSON.stringify(this.searchHistory))
      if (this.searchType === 'book') {
        this.$apiFun.bookGetList({
          book_name: this.searchValue,
          page_num: this.pageNum,
          page_size: 20,
        }).then((res) => {
          if (res.Code === 0) {
            this.hasSearch = true;
            this.list = res.Data.list
          }
        })
      } else if (this.searchType === 'picture') {
        this.$apiFun.pictureGetList({
          picture_name: this.searchValue,
          page_num: this.pageNum,
          page_size: 20,
        }).then((res) => {
          if (res.Code === 0) {
            this.hasSearch = true;
            this.list = res.Data.list
          }
        })
      } else {
        this.$apiFun.videoGetList({
          video_name: this.searchValue,
          page_num: this.pageNum,
          page_size: 20,
        }).then((res) => {
          if (res.Code === 0) {
            this.hasSearch = true;
            this.list = res.Data.list
          }
        })
      }

    },
    goToDetail(id) {
      const TYPE_URL_MAP = {
        book: '/wapBookDetail/',
        picture: '/wapPictureDetail/',
        video: '/wapVideoDetail/',
      }
      this.$router.push(TYPE_URL_MAP[this.searchType] + id)
    },
    handleSearchHistory(searchValue, type) {
      this.searchValue = searchValue;
      this.searchType = type || this.searchType;
      if (type) {
        this.handleTypeChange(type)
      }
      if (this.searchType === 'book') {
        this.$apiFun.bookGetList({
          book_name: searchValue,
          page_num: this.pageNum,
          page_size: 20,
        }).then((res) => {
          if (res.Code === 0) {
            this.hasSearch = true;
            this.list = res.Data.list
          }
        })
      } else if (this.searchType === 'picture') {
        this.$apiFun.pictureGetList({
          picture_name: searchValue,
          page_num: this.pageNum,
          page_size: 20,
        }).then((res) => {
          if (res.Code === 0) {
            this.hasSearch = true;
            this.list = res.Data.list
          }
        })
      } else {
        this.$apiFun.videoGetList({
          video_name: searchValue,
          page_num: this.pageNum,
          page_size: 20,
        }).then((res) => {
          if (res.Code === 0) {
            this.hasSearch = true;
            this.list = res.Data.list
          }
        })
      }

    },
    handleTypeChange(v) {
      const SEARCH_SERVICE_MAP = {
        book: this.$apiFun.getBookHotSearch,
        picture: this.$apiFun.getPictureHotSearch,
        video: this.$apiFun.getVideoHotSearch,
      };
      const SEARCH_HOT_MAP = {
        book: 'HOT BOOKS',
        picture: 'HOT PICTURES',
        video: 'HOT VIDEOS',
      }
      this.hotText = SEARCH_HOT_MAP[v];
      SEARCH_SERVICE_MAP[v]().then(res => {
        if (res.Code == 0) {
          this.hotSearch = res.Data;
        }
      })
    }
  },
  watch: {
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-input__inner {
  border: 1px solid $theme-color;
}

::v-deep .el-input-group__append {
  background-color: $theme-color;
  border: 1px solid $theme-color;
}

::v-deep .el-input-group__prepend {
  background-color: #fff;
  width: 60px;
}

::v-deep .el-select__caret {
  color: #000 !important;
}

.search {
  padding: 10px;
}
</style>