import axios from 'axios'     //引入
import { Message } from 'element-ui';

let baseURL = 'http://www.ogaporn.com/api'

let config = {
  baseURL: baseURL,
  timeout: 60000       //设置最大请求时间
}
const _axios = axios.create(config)
 
// /* 请求拦截器（请求之前的操作） */
// _axios.interceptors.request.use(
//   config => {
//       config.headers.Authorization = getToken;  
//     return config;
//   },
//   err => Promise.reject(err)
// );
 
/* 响应拦截器 */
_axios.interceptors.response.use(
  res => {
    if (res.status === 401 ) {
        Message({
            message: "无权限操作",
            type: "error",
            showClose: true,
        })
    }
	if (res.status === 400 ) {
        Message({
            message: "请求网络失败",
            type: "error",
            showClose: true,
        })
	}
	if (res.data.code === 404 ) {
        Message({
            message: "请求网络失败",
            type: "error",
            showClose: true,
        })
	}
    if (res.status === 500) {
        Message({
            message: "服务器错误，稍后重试",
            type: "error",
            showClose: true,
        })
    }
    return res;
  },
  err => {
    if (err) {
        console.log(err)
        Message({
            message: "服务器错误，稍后重试",
            type: "error",
            showClose: true,
        })
    }
    return Promise.reject(err);
  }
);
 
//封装post,get方法
const http = {
    get(url='',params={},headers={}){
        return new Promise((resolve, reject) => {
        _axios({
            url,
            params,
            method: 'GET',
            headers:headers
        }).then(res => {
            resolve(res.data)
            return res
        }).catch(error => {
            reject(error)
        })
        })
    },
    post(url='',datas={}){
        return new Promise((resolve, reject) => {
        _axios({
            url,
            data:datas,
            method: 'POST',
        }).then(res => {
            resolve(res.data)
            return res
        }).catch(error => {
            reject(error)
        })
        })
    }
}

export default http
