import Vue from "vue";
import ElementUI from "element-ui";
import VueAwesomeSwiper from "vue-awesome-swiper";
import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'

import locale from "../node_modules/element-ui/lib/locale/lang/en";

import App from "./App.vue";
import store from "./store";
import router from "./router";
import apiFun from "./api";

import "element-ui/lib/theme-chalk/index.css";
import "swiper/css/swiper.css";
import "./theme/index.css";

// import $ from 'jquery'

Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.use(VueAwesomeSwiper);
Vue.use(preview)
Vue.prototype.$apiFun = apiFun;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
