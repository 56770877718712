import Vue from 'vue'
import Router from 'vue-router'
import home from './components/allIndex'
import wapHome from './components/wap/wapHome'
import wapRegister from './components/wap/wapRegister.vue'
import wapLogin from './components/wap/wapLogin.vue'
import wapSearch from './components/wap/wapSearch.vue';
import wapBookList from './components/wap/wapBookList.vue';
import wapBookDetail from './components/wap/wapBookDetail.vue';
import wapReading from './components/wap/wapReading.vue';
import wapVideoList from './components/wap/wapVideoList.vue';
import wapVideoDetail from './components/wap/wapVideoDetail.vue';
import wapPictureList from './components/wap/wapPictureList.vue';
import wapPictureDetail from './components/wap/wapPictureDetail.vue';
import book from './components/bookHome'
import bookDetail from './components/bookDetail'
import picture from './components/pictureHome'
import pictureDetail from './components/pictureDetail'
import video from './components/videoHome'
import videoDetail from './components/videoDetail'
import myBook from './components/myBook'
import myPicture from './components/myPicture'
import myVideo from './components/myVideo'
import myUpload from "./components/myUpload"
import chapterReading from './components/chapterReading'


Vue.use(Router)

export default new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: { name: 'home' }
    },
    {
      name: 'home',
      path: '/home',
      component: home
    },
    {
      name: 'wapHome',
      path: '/wapHome',
      component: wapHome
    },
    {
      name: 'wapRegister',
      path: '/wapRegister',
      component: wapRegister
    },
    {
      name: 'wapLogin',
      path: '/wapLogin',
      component: wapLogin
    },
    {
      name: 'wapSearch',
      path: '/wapSearch',
      component: wapSearch
    },
    {
      name: 'wapBookList',
      path: '/wapBookList',
      component: wapBookList
    },
    {
      name: 'wapBookDetail',
      path: '/wapBookDetail/:id',
      component: wapBookDetail
    },
    {
      name: 'wapReading',
      path: '/wapReading/:bookName/:bookId/:chapterId',
      component: wapReading
    },
    {
      name: 'wapVideoList',
      path: '/wapVideoList',
      component: wapVideoList
    },
    {
      name: 'wapVideoDetail',
      path: '/wapVideoDetail/:id',
      component: wapVideoDetail
    },
    {
      name: 'wapPictureList',
      path: '/wapPictureList',
      component: wapPictureList
    },
    {
      name: 'wapPictureDetail',
      path: '/wapPictureDetail/:id',
      component: wapPictureDetail
    },
    {
      name: 'book',
      path: '/book',
      component: book
    },
    {
      name: 'bookDetail',
      path: '/bookDetail/:id',
      component: bookDetail
    },
    {
      name: 'picture',
      path: '/picture',
      component: picture
    },
    {
      name: 'pictureDetail',
      path: '/pictureDetail/:id',
      component: pictureDetail
    },
    {
      name: 'video',
      path: '/video',
      component: video
    },
    {
      name: 'videoDetail',
      path: '/videoDetail/:id',
      component: videoDetail
    },
    {
      name: 'myBook',
      path: '/myBook',
      component: myBook
    },
    {
      name: 'myPicture',
      path: '/myPicture',
      component: myPicture
    },
    {
      name: 'myVideo',
      path: '/myVideo',
      component: myVideo
    },
    {
      name: 'myUpload',
      path: '/myUpload',
      component: myUpload
    },
    {
      name: 'reading',
      path: '/reading/:bookName/:bookId/:chapterId',
      component: chapterReading
    },
  ]
})
