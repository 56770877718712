<template>
  <div class="container">
    <div class="header">
      <img class="logo-img" src="../../../static/favicon.png" alt="" width="80px">
      <el-divider class="divider" direction="vertical"></el-divider>
      <p class="register-text">Login</p>
    </div>

    <div class="register-form">
      <div class="clearfix">
        Open Account
      </div>
      <el-divider></el-divider>
      <el-form :model="formData" :rules="rules">
        <el-form-item label="Phone Number" prop="phoneNumber">
          <el-input v-model="formData.phoneNumber" autocomplete="off" style="width: 100%;"></el-input>
        </el-form-item>
        <el-form-item label="Otp" prop="captcha">
          <div style="width: 100%;margin-top: 40px;">
            <el-input v-model="formData.captcha" autocomplete="off" style="width: calc(100% - 140px);"></el-input>
            <el-button v-if="buttonShow" @click="getCaptcha" style="margin-left: 20px;width: 120px;">Get Otp</el-button>
            <el-button v-if="!buttonShow" style="margin-left: 20px;" key='new' disabled>{{ countSecond
            }}秒后重试</el-button>
          </div>
        </el-form-item>
      </el-form>
      <el-button class="login-btn" type="primary" round @click="login">Login</el-button>
    </div>

  </div>
</template>
     
<script>

export default {
  data() {
    return {
      formData: {
        phoneNumber: '',
        captcha: ''
      },
      rules: {
        phoneNumber: [
          { required: true, message: "please input your phone number", trigger: "blur" }
        ]
      },
      buttonShow: true,
      countSecond: '',
      timer: null,
    };
  },
  mounted() {
  },
  methods: {
    getCaptcha() {
      if (this.formData.phoneNumber === '') {
        this.$message({
          message: "phone number can't be empty",
          type: "error",
          showClose: true,
        })
        return
      }
      this.$apiFun.getOTP(this.formData.phoneNumber)
        .then((res) => {
          if (res.Code === 0) {
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.countSecond = TIME_COUNT;
              this.buttonShow = false;
              this.timer = setInterval(() => {
                if (this.countSecond > 0 && this.countSecond <= TIME_COUNT) {
                  this.countSecond--;
                } else {
                  this.buttonShow = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000)
            }
          }
        }).catch(err => console.error(err))
    },
    login() {
      if (this.formData.phoneNumber === '' || this.formData.captcha === '') {
        this.$message({
          message: "phone number and captcha can't be empty",
          type: "error",
          showClose: true,
        })
        return
      }
      this.$apiFun.login({
        phoneNumber: this.formData.phoneNumber,
        captcha: this.formData.captcha
      })
        .then((res) => {
          if (res.Code === 0) {
            let userInfo = {
              isLogin: true,
              memberId: res.Data.id,
              memberName: res.Data.name,
              token: res.Data.token,
              isAdmin: res.Data.isAdmin,
            };
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            // this.$store.state.userInfo = userInfo

            this.$message({
              message: "login success",
              type: 'success',
              showClose: true,
            });
            this.$router.push('/wapHome')
          } else if (res.Code === 1) {
            this.$message({
              message: "captcha has expired",
              type: "error",
              showClose: true,
            })
          } else if (res.Code === 2) {
            this.$message({
              message: "captcha vaildation error",
              type: "error",
              showClose: true,
            })
          }
        })
    }
  },
  watch: {
  }
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 1280px;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #fff;

  .divider {
    height: 40px;
    margin: 0 20px;
  }

  .register-text {
    font-weight: bold;
    font-size: 24px;
    color: $theme-color;
    flex: 1;
    height: 80px;
    line-height: 80px;
    margin: 0;
    text-align: center;
  }
}

.register-form {
  width: 90%;
  margin: 0 auto;
  margin-top: 20vh;
  max-width: 500px;
  padding: 3%;

  .clearfix {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
  }

  .login-btn {
    width: 100%;
  }
}
</style>