<!--userManageDialog   -->
<template>
  <div class="videoManageDialog">
    <el-dialog :title="tmp_dialog.title" width="45%" :visible.sync="tmp_dialog.show" center>
      <el-form ref="ref_form_videoManage" :model="tmp_formData" :rules="rules" label-width="100px">
        <el-form-item label="name" prop="name">
          <el-input v-model="tmp_formData.name" autocomplete="off" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="author" prop="author">
          <el-input v-model="tmp_formData.author" autocomplete="off" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="introduction" prop="synopsis">
          <el-input v-model="tmp_formData.synopsis" autocomplete="off" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="video" prop="fileUrl">
          <el-upload
            action=''
            accept=".mp4"
            :limit="1"
            :auto-upload="false"
            :file-list="fileList"
            :on-change="videoHandleChange"
            :on-remove="videoHandleRemove"
          >
            <el-button size="small" >click to upload</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="cover" prop="coverUrl">
          <el-upload
            action=''
            accept=".jpg,.png"
            list-type="picture-card"
            :limit="1"
            :auto-upload="false"
            :file-list="coverList"
            :on-change="coverHandleChange"
            :on-remove="coverHandleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="tmp_dialog.show = false">cancle</el-button>
        <el-button
          v-if="tmp_dialog.option == 'add'"
          @click="add('ref_form_videoManage')"
          type="primary"
        >confirm</el-button>
        <el-button
          v-if="tmp_dialog.option == 'edit'"
          @click="edit('ref_form_videoManage')"
          type="primary"
        >confirm</el-button>
      </div>
    </el-dialog>
  </div>
</template>
   
<script>

export default {
  name: "videoManageDialog",
  props: ["dialog", "formData"],
  data() {
    return {
      tmp_dialog : null,
      tmp_formData: null,
      fileList: [],
      coverList: [],
      rules: {
        name: [
          { required: true, message: "please enter a name", trigger: "blur" }
        ],
      },
      fileChanged: false,
      coverChanged: false
    };
  },
  created() {},
  mounted() {
    this.tmp_dialog = this.dialog
    this.tmp_formData = this.formData
  },
  methods: {
    videoHandleChange(file, fileList) {
      this.fileChanged = true;
      this.fileList = [...fileList];
    },
    videoHandleRemove(file, fileList) {
      this.fileList = [...fileList];
    },
    coverHandleChange(file, fileList) {
      this.coverChanged = true;
      this.coverList = [...fileList];
    },
    coverHandleRemove(file, fileList) {
      this.coverChanged = true;
      this.coverList = [...fileList];
    },
    add(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit("add", this.tmp_formData, [...this.fileList],[...this.coverList]);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    edit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit("edit", this.tmp_formData, [...this.fileList],this.fileChanged,[...this.coverList],this.coverChanged);
          this.fileChanged = false;
          this.coverChanged = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
  watch: {
    dialog: {
      handler(newV){
        this.tmp_dialog = JSON.parse(JSON.stringify(newV));
      },
      deep:true
    },
    formData: {
      handler(newV){
        this.tmp_formData = JSON.parse(JSON.stringify(newV));//将监听到修改的值赋予到dataAttrs中
        const file = [];
        if (this.formData.fileUrl) {
          file.push({ name:this.formData.name+'.mp4', url: this.formData.fileUrl });
        }
        this.fileList = [...file]
        const cover = [];
        if (this.formData.coverUrl) {
          cover.push({ url: this.formData.coverUrl });
        }
        this.coverList = [...cover]
      },
      deep:true
    }
  }
};
</script>
<style lang="scss" scoped>
.videoManageDialog {
  /*去除upload组件过渡效果*/
  ::v-deep .el-upload-list__item {
    transition: none !important;
  }
}
</style>