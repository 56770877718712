<template>
  <div>
    <!-- 导航栏 -->
    <header id="header">
      <div class="navbar-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav class="navbar navbar-expand-lg">
                <div class="logo">
                  <img src="../../static/favicon.png" alt="">
                </div>
                <div
                  class="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  <ul id="nav" class="navbar-nav ml-auto">
                    <li class="nav-item active">
                      <a class="page-scroll" href="#"
                        >Home <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToBook"
                        >Books <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToPicture"
                        >Pictures
                        <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToVideo"
                        >Videos <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>
                  </ul>
                </div>

                <!-- 登录按钮 -->
                <div v-if="!logined" class="search-icon">
                  <span class="open-search">
                    <i class="mdi btn btn-common" @click="login">Login</i>
                  </span>
                </div>
                <div v-if="logined" style="margin-left:20px">
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      {{ memberName }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="1">My BookShelf</el-dropdown-item>
                      <el-dropdown-item command="2">My Gallery</el-dropdown-item>
                      <el-dropdown-item command="3">My VideoLibrary</el-dropdown-item>
                      <el-dropdown-item command="4" v-if="isAdmin===1">My Upload</el-dropdown-item>
                      <el-dropdown-item command="5">Logout</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- 主体 -->

    <section class="team section-padding section-dark" style="margin-top:40px">
      <div class="container">
        <div class="row">
          <div class="col-md-12 wow animated fadeInLeft" data-wow-delay=".2s">
            <h1 class="section-title">Books</h1>
          </div>
        </div>
        <div class="row">
          <div
            v-for="book in books.slice(0, 8)"
            v-bind:key="book.index"
            class="col-md-6 col-lg-3 col-xl-3 wow animated fadeInUp"
            data-wow-delay=".3s"
          >
            <div class="single-team-widget">
              <img :src="book.coverUrl" class="img-g" alt="" />
              <div class="team-member-info">
                <div class="know-more">
                  <a
                    class="btn btn-round btn-fab btn-xs"
                    href="javascript:void(0)"
                    @click="getBookDetail(book.id)"
                    ><i class="material-icons mdi mdi-arrow-right"></i>
                    <div class="ripple-container"></div>
                  </a>
                </div>
                <h2 class="subtitle" style="text-align:center;">{{ book.name }}</h2>
                <!-- <p>Co-Founder</p> -->
              </div>
            </div>
          </div>
        </div>
        <a
          href="javascript:void(0)"
          @click="goToBook"
          class="animated4 btn btn-common"
          data-ripple-color="#000"
          ><i class="material-icons mdi mdi-lightbulb-outline"></i> More
          <div class="ripple-container"></div
        ></a>
      </div>
    </section>

    <section class="team section-padding section-dark">
      <div class="container">
        <div class="row">
          <div class="col-md-12 wow animated fadeInLeft" data-wow-delay=".2s">
            <h1 class="section-title">Pictures</h1>
          </div>
        </div>
        <div class="row">
          <div
            v-for="picture in pictures.slice(0, 8)"
            v-bind:key="picture.index"
            class="col-md-6 col-lg-3 col-xl-3 wow animated fadeInUp"
            data-wow-delay=".3s"
          >
            <div class="single-team-widget">
              <img :src="picture.fileUrl.split(',')[0]" class="img-g" alt="" />
              <div class="team-member-info">
                <div class="know-more">
                  <a
                    class="btn btn-round btn-fab btn-xs"
                    @click="getPictureDetail(picture.id)"
                    href="javascript:void(0)"
                    ><i class="material-icons mdi mdi-arrow-right"></i>
                    <div class="ripple-container"></div>
                  </a>
                </div>
                <h2 class="subtitle" style="text-align:center;">{{ picture.name }}</h2>
                <!-- <p>Co-Founder</p> -->
              </div>
            </div>
          </div>
        </div>
        <a
          href="javascript:void(0)"
          @click="goToPicture"
          class="animated4 btn btn-common"
          data-ripple-color="#000"
          ><i class="material-icons mdi mdi-lightbulb-outline"></i>  More
          <div class="ripple-container"></div
        ></a>
      </div>
    </section>

    <section class="team section-padding section-dark">
      <div class="container">
        <div class="row">
          <div class="col-md-12 wow animated fadeInLeft" data-wow-delay=".2s">
            <h1 class="section-title">Videos</h1>
          </div>
        </div>
        <div class="row">
          <div
            v-for="video in videos.slice(0, 8)"
            v-bind:key="video.index"
            class="col-md-6 col-lg-3 col-xl-3 wow animated fadeInUp"
            data-wow-delay=".3s"
          >
            <div class="single-team-widget">
              <img :src="video.coverUrl" class="img-g" alt="" />
              <div class="team-member-info">
                <div class="know-more">
                  <a
                    class="btn btn-round btn-fab btn-xs"
                    href="javascript:void(0)"
                    @click="getVideoDetail(video.id)"
                    ><i class="material-icons mdi mdi-arrow-right"></i>
                    <div class="ripple-container"></div>
                  </a>
                </div>
                <h2 class="subtitle" style="text-align:center;">{{ video.name }}</h2>
                <!-- <p>Co-Founder</p> -->
              </div>
            </div>
          </div>
        </div>
        <a
          href="javascript:void(0)"
          @click="goToVideo"
          class="animated4 btn btn-common"
          data-ripple-color="#000"
          ><i class="material-icons mdi mdi-lightbulb-outline"></i>  More
          <div class="ripple-container"></div
        ></a>
      </div>
    </section>
    <loginDialog :dialog="dialog" @callback="getMemberInfo"></loginDialog>
  </div>
</template>




<style src="../assets/css/bootstrap-4.5.0.min.css"  scoped></style>
<style src="../assets/css/materialdesignicons.min.css"  scoped></style>
<style src="../assets/css/material.min.css"  scoped></style>
<style src="../assets/css/ripples.min.css"  scoped></style>

<style src="../assets/css/magnific-popup.css"  scoped></style>
<style src="../assets/css/animate.css"  scoped></style>
<style src="../assets/css/style.css"  scoped></style>
<style src="../assets/css/responsive.css"  scoped></style>
<style src="../assets/css/colors/indigo.css"  scoped></style>


<style scoped>
.logo {
    width:60px;
    height:60px;
}
.logo img  {
    width: 100%;
    height:100%;
}
.img-g {
  max-width: 100%;
  height: 260px;
}
</style>

<script>
import loginDialog from "./loginDialog.vue";
export default {
  components: {loginDialog},
  data() {
    return {
      dialog: {
        show: false,
        title: ""
      },
      books: [
        {
          id : 1,
          name: "Foo intersting GUN idoaijsdjipqiwojd",
          coverUrl: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        },
      ],
      pictures: [
        {
          id : 1,
          name: "Foo intersting GUN idoaijsdjipqiwojd",
          fileUrl: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        },
      ],
      videos: [
        {
          id : 1,
          name: "Foo intersting GUN idoaijsdjipqiwojd",
          coverUrl: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        },
      ],
      logined: false,
      memberName: '',
      memberId: '',
      token: '',
      isAdmin: 0,
    };
  },
  mounted() { 
    this.getMemberInfo();
    this.getBookList();
    this.getPictureList();
    this.getVideoList();
  },
  methods: {
    getBookList(){
      this.books = []
      this.$apiFun.bookGetList({
        page_num: 1,
        page_size:8,
      }).then((res) => {
        if(res.Code === 0){
          this.books = res.Data.list
        }
      })
    },
    getBookDetail(id){
      this.$router.push("/bookDetail/"+id)
    },
    getPictureList(){
      this.pictures = []
      this.$apiFun.pictureGetList({
        page_num: 1,
        page_size:8,
      }).then((res) => {
        if(res.Code === 0){
          this.pictures = res.Data.list
        }
      })
    },
    getPictureDetail(id){
      this.$router.push("/pictureDetail/"+id)
    },
    getVideoList(){
      this.videos = []
      this.$apiFun.videoGetList({
        page_num: 1,
        page_size:8,
      }).then((res) => {
        if(res.Code === 0){
          this.videos = res.Data.list
        }
      })
    },
    getVideoDetail(id){
      this.$router.push("/videoDetail/"+id)
    },
    goToBook(){
      this.$router.push("/book");
    },
    goToPicture(){
      this.$router.push("/picture");
    },
    goToVideo(){
      this.$router.push("/video");
    },
    login(){
      this.dialog = {
        show: true,
        title: "Login",
      }
    },
    getMemberInfo(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if(userInfo != null && userInfo.isLogin){
        this.logined = true
        this.memberName = userInfo.memberName
        this.memberId = userInfo.memberId
        this.token = userInfo.token
        this.isAdmin = userInfo.isAdmin
      }
    },
    logout() {
      this.logined = false;
      this.memberName = '';
      this.memberId = '';
      this.token = '';
      localStorage.clear();
      setTimeout(() => {
        this.$router.push("/home");//退出登录后2秒后跳转至首页
      }, 500)
      //加()=>{},()=>{} 可解决路由重复后台报错问题
    },
    handleCommand(command) {
      if(command === "1"){
        this.$router.push("/myBook");
      }else if(command === "2"){
        this.$router.push("/myPicture");
      }else if(command === "3"){
        this.$router.push("/myVideo");
      }else if(command === "4"){
        this.$router.push("/myUpload");
      }else {
        this.logout()
      }
    }
  }
};
</script>