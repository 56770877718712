<template>
  <div>
    <!-- 导航栏 -->
    <header id="header">
      <div class="navbar-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav class="navbar navbar-expand-lg">
                <div class="logo">
                  <img src="../../static/favicon.jpeg" alt="">
                </div>
                <el-input
                  v-model="searchName"
                  clearable
                  placeholder="Enter a name to search"
                  style="width: 200px; margin-right: 7px"
                />
                <el-button
                  type="text"
                  icon="el-icon-search"
                  style="color:white;"
                  @click="pageNum = 1,search(searchName)"
                >search</el-button>
                <div
                  class="collapse navbar-collapse sub-menu-bar"
                  id="navbarSupportedContent"
                >
                  <ul id="nav" class="navbar-nav ml-auto">
                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToHome"
                        >Home <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToBook"
                        >Books <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item">
                      <a class="page-scroll" href="#" @click="goToPicture"
                        >Pictures
                        <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>

                    <li class="nav-item active">
                      <a class="page-scroll" href="#" @click="reset"
                        >Videos <span><i class="mdi mdi-chevron-down"></i></span
                      ></a>
                    </li>
                  </ul>
                </div>

                <!-- 登录按钮 -->
                <div v-if="!logined" class="search-icon">
                  <span class="open-search">
                    <i class="mdi btn btn-common" @click="login">Login</i>
                  </span>
                </div>
                <div v-if="logined" style="margin-left:20px">
                  <el-dropdown trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                      {{ memberName }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="1">My BookShelf</el-dropdown-item>
                      <el-dropdown-item command="2">My Gallery</el-dropdown-item>
                      <el-dropdown-item command="3">My VideoLibrary</el-dropdown-item>
                      <el-dropdown-item command="4" v-if="isAdmin===1">My Upload</el-dropdown-item>
                      <el-dropdown-item command="5">Logout</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </header>

    <!-- 主体 -->

    <section class="team section-padding section-dark" style="margin-top:40px">
      <div class="container" style="margin-bottom: 200px;">
        <videoManageDialog :dialog="uploadDialog" :formData="formData" @add="add(arguments)" @edit="edit(arguments)"></videoManageDialog>
        <div class="row">
          <div class="col-md-12 wow animated fadeInLeft" data-wow-delay=".2s">
            <el-col :span="22"><div class="grid-content bg-purple"></div><h1 class="section-title">Videos</h1></el-col>
            <el-col :span="2"><div class="grid-content bg-purple-light"></div><el-button v-if="isAdmin===1" @click="handleAdd()" style="font-size: 20px;" type="text">Upload</el-button></el-col>
          </div>
        </div>
        <div class="row">
          <div
            v-for="video in videos"
            v-bind:key="video.index"
            class="col-md-6 col-lg-3 col-xl-3 wow animated fadeInUp"
            data-wow-delay=".3s"
          >
            <div class="single-team-widget">
              <img :src="video.coverUrl" class="img-g" alt="" />
              <div class="team-member-info">
                <div class="know-more">
                  <a
                    class="btn btn-round btn-fab btn-xs"
                    href="javascript:void(0)"
                    @click="getVideoDetail(video.id)"
                    ><i class="material-icons mdi mdi-arrow-right"></i>
                    <div class="ripple-container"></div>
                  </a>
                </div>
                <h2 class="subtitle" style="text-align:center;">{{ video.name }}</h2>
                <!-- <p>Co-Founder</p> -->
              </div>
            </div>
          </div>
        </div>
        <!-- <div style="float:right;margin-top:10px;margin-bottom:10px;"> -->
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="20"
            layout="total, prev, pager, next, jumper"
            style="float:right;margin-bottom: 100px;margin-top: 50px;"
            :total="total">
          </el-pagination>
        <!-- </div> -->
      </div>
    </section>
    <loginDialog :dialog="dialog" @callback="getMemberInfo"></loginDialog>
  </div>
</template>




<style src="../assets/css/bootstrap-4.5.0.min.css"  scoped></style>
<style src="../assets/css/materialdesignicons.min.css"  scoped></style>
<style src="../assets/css/material.min.css"  scoped></style>
<style src="../assets/css/ripples.min.css"  scoped></style>

<style src="../assets/css/magnific-popup.css"  scoped></style>
<style src="../assets/css/animate.css"  scoped></style>
<style src="../assets/css/style.css"  scoped></style>
<style src="../assets/css/responsive.css"  scoped></style>
<style src="../assets/css/colors/indigo.css"  scoped></style>

<style scoped>
.logo {
    width:60px;
    height:60px;
    margin-right: 20px;
}
.logo img  {
    width: 100%;
    height:100%;
}
.img-g {
  max-width: 100%;
  height: 260px;
}
</style>

<script>
import loginDialog from "./loginDialog.vue";
import videoManageDialog from "./videoManageDialog.vue";
export default {
  components: {loginDialog,videoManageDialog},
  data() {
    return {
      searchName: "",
      isSearchingName: "",
      videos: [
        {
          id: 1,
          name: "Foo",
          coverUrl: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        },
      ],
      pageNum: 1, // 当前页 默认设值1
      total: 0 ,// 条目总数
      logined: false,
      memberName: '',
      memberId: '',
      token: '',
      dialog: {
        show: false,
        title: ""
      },
      isAdmin:0,
      uploadDialog:{
        show: false,
        title: ""
      },
      formData: {},
    };
  },
  mounted() {
    this.getMemberInfo();
    this.getList();
  },
  methods: {
        //打开新增窗口
      handleAdd() {
        this.formData = {
          name: "",
          author:"",
          synopsis:"",
          fileUrl: "",
          coverUrl:"",
        };
        this.uploadDialog = {
          show: true,
          title: "upload",
          option: "add"
        };
        console.log(this.formData)
      },
      add(msg) {
        const tmp_formData = msg[0];
        const fileList = msg[1];
        if (fileList.length === 0){
          this.$message({
            message: "file cannot be empty",
            type: "error",
            showClose: true,
          })
          return
        }
        this.$apiFun.videoAdd(this.memberId,tmp_formData,fileList).then((res) => {
          if(res.Code === 0){
            const coverList = msg[2];
            if (coverList.length > 0) {
              this.$apiFun.videoCoverAdd(tmp_formData.name,coverList)
              .then((res) => {
                if (res.Code == 0){
                  this.$message({
                    message: "Uploaded successfully, under review. For details, please go to 'My Upload' to view",
                    type: 'success',
                    showClose: true,
                  });
                  this.getList();
                  this.uploadDialog.show = false; 
                }
              })
            } else {
              this.$message({
                message: "Uploaded successfully, under review. For details, please go to 'My Upload' to view",
                type: 'success',
                showClose: true,
              });
              this.getList();
              this.uploadDialog.show = false; 
            }  
          }else if (res.Code === 1){
            this.$message({
              message: "The video already exists with a duplicate name",
              type: "error",
              showClose: true,
            })
          }
        })
      },
    getList(){
      this.videos = []
      this.$apiFun.videoGetList({
        page_num: this.pageNum,
        page_size:20,
      }).then((res) => {
        if(res.Code === 0){
          this.videos = res.Data.list
          this.total = res.Data.total
        }
      })
    },
    getVideoDetail(id){
      this.$router.push("/videoDetail/"+id)
    },
    reset(){
      this.searchName = ""
      this.isSearchingName = ""
      this.pageNum = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      if (this.isSearchingName != '') {
        this.search(this.isSearchingName);
      }else {
        this.getList();
      }
    },
    //根据名称查询
    search(searchName) {
      this.isSearchingName = searchName;
      if(searchName === ""){
        this.getList();
        return
      }
      this.videos = []
      this.$apiFun.videoGetList({
        video_name: searchName,
        page_num: this.pageNum,
        page_size:20,
      }).then((res) => {
        if(res.Code === 0){
          this.videos = res.Data.list
          this.total = res.Data.total
        }
      })
    },
    goToHome(){
      this.$router.push("/home");
    },
    goToPicture(){
      this.$router.push("/picture");
    },
    goToBook(){
      this.$router.push("/book");
    },
    login(){
      this.dialog = {
        show: true,
        title: "Login",
      }
    },
    getMemberInfo(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if(userInfo != null && userInfo.isLogin){
        this.logined = true
        this.memberName = userInfo.memberName
        this.memberId = userInfo.memberId
        this.token = userInfo.token
        this.isAdmin = userInfo.isAdmin
      }
    },
    logout() {
      this.logined = false;
      this.memberName = '';
      this.memberId = '';
      this.token = '';
      localStorage.clear();
      setTimeout(() => {
        this.$router.push("/home");//退出登录后2秒后跳转至首页
      }, 500)
      //加()=>{},()=>{} 可解决路由重复后台报错问题
    },
    handleCommand(command) {
      if(command === "1"){
        this.$router.push("/myBook");
      }else if(command === "2"){
        this.$router.push("/myPicture");
      }else if(command === "3"){
        this.$router.push("/myVideo");
      }else if(command === "4"){
        this.$router.push("/myUpload");
      }else {
        this.logout()
      }
    }
  }
};
</script>