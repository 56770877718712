<template>
  <div style="width:100%;background-color:rgb(251,246,236)">
    <el-row type="flex" justify="center">
      <el-col :span="22" style="margin-top:10px">
        <div class="tastrule_center" v-html="txt" > </div>
      </el-col>
    </el-row>
    <el-row v-if="txt" type="flex" justify="center" style="padding: 10px 0;">
      <el-col :span="8">
        <div v-if="lastChapterId>0" style="text-align:center;">
          <a class="book-action" @click="jumpChapter(lastChapterId)">Last Chapter</a>
        </div>
      </el-col>
      <el-col :span="6">         
        <div style="text-align:center;">
          <a class="book-action" @click="getContents">Contents</a>
        </div>         
      </el-col>
      <el-col :span="8">
        <div v-if="nextChapterId>0" style="text-align:center;">
          <a class="book-action" @click="jumpChapter(nextChapterId)">Next Chapter</a>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
   
<script>

export default {
  data() {
    return {
      txt:'',
      nextChapterId:0,
      lastChapterId:0,
    };
  },
  mounted() {
    this.getChapter(this.$route.params.chapterId)
    this.getLabel()
  },
  methods: {
    getChapter(chapterId){
      this.$apiFun.chapter({
        book_name: this.$route.params.bookName,
        chapter_id: chapterId
      }).then((res) => {
        this.txt = res
        this.$nextTick(() => {
          document.body.scrollIntoView()
        })
      })
    },
    getLabel(){
      this.$apiFun.getChapterLabel({
        book_id: this.$route.params.bookId,
        chapter_id: this.$route.params.chapterId
      }).then((res) => {
        this.nextChapterId = res.Data.nextId
        this.lastChapterId = res.Data.lastId
      })
      
    },
    getContents(){
      this.$router.push("/wapBookDetail/"+this.$route.params.bookId)
    },
    jumpChapter(id){
      this.getChapter(id)
      this.$router.push("/wapReading/"+this.$route.params.bookName+"/"+this.$route.params.bookId+"/"+id);
      this.getLabel()
    }
  }
};
</script>

<style scoped lang="scss">
a:hover {color: orange} /* 鼠标移动到链接上 */
.tastrule_center {
  color: #333333;
  line-height: 30px;
  white-space: pre-wrap;
}
.book-action {
  color: $theme-color;
}
</style>