<template>
  <div>
    <wapHeader />
    <div style="padding: 0 10px;margin-top: 10px;">
      <div class="tabs">
        <div v-for="tab in tabs" :key="tab" class="tab" :class="{ 'tab-active': activeTab === tab }"
          @click="changeTab(tab)">
          <span>{{ tab }}</span>
        </div>
      </div>

      <swiper class="mt20" :options="swiperOption">
        <swiper-slide v-for="banner in bannerList" :key="banner.id">
          <img style="width: calc(100vw - 20px);height: 180px" :src="banner.fileUrl" :alt="banner.name"
            :data-banner="JSON.stringify(banner)">
        </swiper-slide>
      </swiper>

      <!-- <el-row class="mt20">
        <el-col class="category" :span="8">
          <img src="http://s.faloo.com/wap/images/newWap/index/nav_icon1.png" alt="" width="30px" height="30px">
          <p>All Categories</p>
        </el-col>
        <el-col class="category" :span="8"><img src="https://s.faloo.com/wap/images/newWap/index/nav_icon2.png"
            width="30px" height="30px" alt="">
          <p>Ranking List</p>
        </el-col>
        <el-col class="category" :span="8"><img src="https://s.faloo.com/wap/images/newWap/index/nav_icon5.png"
            width="30px" height="30px" alt="">
          <p>Recharging</p>
        </el-col>
      </el-row> -->

      <div class="hot-recommand">
        <div class="hot-header">
          <h4 class="hot-text">Hot Books</h4>
          <span class="hot-more" @click="getMore('Books')">More</span>
        </div>
        <el-row class="hot-list">
          <el-col class="hot-item" :span="8" v-for="book in books" :key="book.name" @click.native="goToHotDetail('Books', book.id)">
            <img :src="book.coverUrl" width="100px" height="150px" alt="">
            <p>{{ book.name }}</p>
          </el-col>
        </el-row>
      </div>

      <div class="hot-recommand">
        <div class="hot-header">
          <h4 class="hot-text">Hot Pictures</h4>
          <span class="hot-more" @click="getMore('Pictures')">More</span>
        </div>
        <el-row class="hot-list">
          <el-col class="hot-item" :span="8" v-for="picture in pictures" :key="picture.name" @click.native="goToHotDetail('Pictures', picture.id)">
            <img :src="picture.fileUrl.split(',')[0]" width="100px" height="150px" alt="">
            <p>{{ picture.name }}</p>
          </el-col>
        </el-row>
      </div>

      <div class="hot-recommand">
        <div class="hot-header">
          <h4 class="hot-text">Hot Videos</h4>
          <span class="hot-more" @click="getMore('Videos')">More</span>
        </div>
        <el-row class="hot-list">
          <el-col class="hot-item" :span="8" v-for="item in videos" :key="item.name" @click.native="goToHotDetail('Videos', item.id)">
            <img :src="item.coverUrl" width="100px" height="150px" alt="">
            <p>{{ item.name }}</p>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
     
<script>
import wapHeader from './wapHeader.vue';
export default {
  components: {
    wapHeader,
  },
  data() {
    return {
      tabs: ['Home', 'Books', 'Pictures', 'Videos'],
      activeTab: 'Home',
      bannerList: [],
      swiperOption: {
        slidesPerView: 1,
        // 设置自动轮播
        autoplay: {
          delay: 5000 // 5秒切换一次
        },
        // 设置轮播可循环
        loop: true,
        on: {
          click: this.goToBannerDetail
        }
      },
      books: [],
      pictures: [],
      videos: [],
    };
  },
  mounted() {
    this.$apiFun.getBannerList().then(res => {
      const { Code, Data } = res;
      if (Code == 0) {
        this.bannerList = Data.list;
      }
    }).catch((e) => console.error(e))
    this.$apiFun.bookGetList({
      page_num: 1,
      page_size: 3,
    }).then((res) => {
      if (res.Code === 0) {
        this.books = res.Data.list;
      }
    })
    this.$apiFun.pictureGetList({
      page_num: 1,
      page_size: 3,
    }).then((res) => {
      if (res.Code === 0) {
        this.pictures = res.Data.list;
      }
    })
    this.$apiFun.videoGetList({
      page_num: 1,
      page_size: 3,
    }).then((res) => {
      if (res.Code === 0) {
        this.videos = res.Data.list;
      }
    })
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab
      const tabMap = {
        Books: '/wapBookList',
        Videos: '/wapVideoList',
        Pictures: '/wapPictureList'
      }
      this.$router.push(tabMap[tab]);
    },

    goToBannerDetail(e) {
      const BANNER_MAP = {
        0: '/wapBookDetail/',
      }
      const banner = JSON.parse(e.target.getAttribute('data-banner'))
      this.$router.push(BANNER_MAP[banner.type] + banner.bannerId)
    },

    goToHotDetail(tab, id) {
      const tabMap = {
        Books: '/wapBookDetail/',
        Videos: '/wapVideoDetail/',
        Pictures: '/wapPictureDetail/',
      }

      this.$router.push(tabMap[tab] + id);
    },
    
    getMore(tab) {
      const tabMap = {
        Books: '/wapBookList',
        Videos: '/wapVideoList',
        Pictures: '/wapPictureList'
      }
      this.$router.push(tabMap[tab]);
    }
  },
  watch: {
  }
};
</script>
<style lang="scss" scoped>
.tabs {
  display: flex;
  padding: 5px 0;
  border-radius: 10px;
  background-color: $theme-color;
  color: #000;

  .tab {
    width: 25%;
    text-align: center;
    font-size: 16px;

    span {
      display: inline-block;
      height: 36px;
      line-height: 36px;
    }
  }

  .tab-active {
    color: #fff;

    span {
      border-bottom: 1px solid #fff;
    }
  }
}

.mt20 {
  margin-top: 20px;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 14px;
  }
}

.hot-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px dashed #777;

  h4 {
    margin: 5px 0;
  }
}

.hot-text::before {
  content: "";
  color: $theme-color;
  margin-right: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: $theme-color;
  display: inline-block;
}

.hot-list {
  margin-top: 10px;
}

.hot-item {
  p {
    margin-top: 5px;
  }
}
</style>