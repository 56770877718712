<template>
  <el-pagination background :hide-on-single-page="true" class="pagination" layout="prev, pager, next" :total="total">
  </el-pagination>
</template>
<script>

export default {
  data() {
    return {

    }
  },
  props: ['total'],  
  methods: {

  }
}
</script>
<style scoped>
.pagination {
  margin-top: 10px;
}
</style>