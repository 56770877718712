<template>
    <div style="width:100%;conheight;min-height: 800px;background-color:rgb(251,246,236)">
      <el-row type="flex" justify="center">
        <el-col :span="13" style="margin-top:100px">
          <div class="tastrule_center" v-html="txt" > </div>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col :span="3" style="margin-top:100px;margin-bottom: 100px;">
          <div v-if="lastChapterId>0" style="text-align:center;">
            <span style="margin-left:15px;margin-right:15px;font-size: 30px" > | </span>
            <a style="font-size: 20px" @click="jumpChapter(lastChapterId)">Last Chapter</a>
          </div>
        </el-col>
        <el-col :span="3" style="margin-top:100px;margin-bottom: 100px;">         
          <div  style="text-align:center;">
            <span style="margin-left:15px;margin-right:15px;font-size: 30px" > | </span>
            <a style="font-size: 20px;" @click="getContents">Contents</a>
            <span style="margin-left:15px;margin-right:15px;font-size: 30px"> | </span>
          </div>         
        </el-col>
        <el-col :span="3" style="margin-top:100px;margin-bottom: 100px;">
          <div v-if="nextChapterId>0" style="text-align:center;">
            <a style="font-size: 20px" @click="jumpChapter(nextChapterId)">Next Chapter</a>
            <span style="margin-left:15px;margin-right:15px;font-size: 30px" > | </span>
          </div>
        </el-col>
      </el-row>
    </div>
  </template>
     
  <script>
  
  export default {
    data() {
      return {
        txt:'',
        nextChapterId:0,
        lastChapterId:0,
      };
    },
    mounted() {
      this.getChapter(this.$route.params.chapterId)
      this.getLabel()
    },
    methods: {
      getChapter(chapterId){
        this.$apiFun.chapter({
          book_name: this.$route.params.bookName,
          chapter_id: chapterId
        }).then((res) => {
          this.txt = res
        })
      },
      getLabel(){
        this.$apiFun.getChapterLabel({
          book_id: this.$route.params.bookId,
          chapter_id: this.$route.params.chapterId
        }).then((res) => {
          this.nextChapterId = res.Data.nextId
          this.lastChapterId = res.Data.lastId
        })
        
      },
      getContents(){
        this.$router.push("/bookDetail/"+this.$route.params.bookId)
      },
      jumpChapter(id){
        this.getChapter(id)
        this.$router.push("/reading/"+this.$route.params.bookName+"/"+this.$route.params.bookId+"/"+id);
        this.getLabel()
      }
    }
  };
  </script>

  <style scoped>
  a:hover {color: orange} /* 鼠标移动到链接上 */
  .tastrule_center {
    color: #333333;
    line-height: 30px;
    white-space: pre-wrap;
	}
  </style>