<template>
  <div>
    <wapHeader />
    <div class="list-container">
      <div class="tabs">
        <div v-for="tab in tabs" :key="tab.value" class="tab" :class="{ 'tab-active': activeTab === tab.value }"
          @click="changeTab(tab.value)">
          <span>{{ tab.label }}</span>
        </div>
      </div>
      <el-card shadow="hover" @click.native="goToDetail(book.id)" style="margin-top: 10px;" v-for="book in books">
        <div style="display: flex;">
          <img :src="book.coverUrl" width="60px" height="80px" alt="">
          <div style="margin-left: 10px;display: flex;flex-direction: column;">
            <h3 class="book-title">{{ book.name }}</h3>
            <!-- <div style="font-size: 12px;">
              <span>Author: </span>
              <span>{{ book.author }}</span>
            </div> -->
          </div>
        </div>
      </el-card>
    </div>
    <wapPagination :total="total" />
  </div>
</template>
     
<script>
import wapHeader from './wapHeader.vue';
import wapPagination from './wapPagination.vue';
export default {
  components: {
    wapHeader,
    wapPagination,
  },
  data() {
    return {
      tabs: [
        { label: 'Latest', value: 'latest' },
        { label: 'Weekly', value: 'weekly' },
        { label: 'Monthly', value: 'monthly' },
        { label: 'Total', value: 'total' },
      ],
      activeTab: 'latest',
      books: [],
      total: 0,
    };
  },
  mounted() {
    this.getBookList();
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
    getBookList() {
      this.$apiFun.bookGetList({
        page_num: 1,
        page_size: 8,
      }).then((res) => {
        if (res.Code === 0) {
          this.books = res.Data.list;
          this.total = res.Data.total;
        }
      })
    },
    goToDetail(id) {
      this.$router.push("/wapBookDetail/" + id)
    }
  },
  watch: {
  }
};
</script>
<style lang="scss" scoped>
.list-container {
  padding: 10px;
}

.book-title {
  margin: 0;
}

.tabs {
  display: flex;
  padding: 5px 0;
  border-radius: 10px;
  background-color: $theme-color;
  color: #000;

  .tab {
    width: 25%;
    text-align: center;
    font-size: 16px;

    span {
      display: inline-block;
      height: 36px;
      line-height: 36px;
    }
  }

  .tab-active {
    color: #fff;

    span {
      border-bottom: 1px solid #fff;
    }
  }
}
</style>