<template>
  <div class="container">
    <div class="header">
      <img class="logo-img" src="/static/favicon.png" alt="" width="120px">
      <el-divider class="divider" direction="vertical"></el-divider>
      <p class="register-text">Register</p>
    </div>
    <el-card class="register-form">
      <div slot="header" class="clearfix">
        <span>Open Account</span>
      </div>
      <el-form :model="formData" :rules="rules" label-width="100px">
        <el-form-item label="Phone Number" label-width="120px" prop="phoneNumber">
          <el-input v-model="formData.phoneNumber" autocomplete="off" style="width: 90%;float: left;"></el-input>
        </el-form-item>
        <el-form-item label="Captcha" label-width="120px" prop="captcha">
          <el-input v-model="formData.captcha" autocomplete="off" style="width: 50%;float: left"></el-input>
          <el-button v-if="buttonShow" @click="getCaptcha" style="float:left;margin-left: 20px;">Get Captcha</el-button>
          <el-button v-if="!buttonShow" style="float:left;margin-left: 20px;" key='new' disabled>{{ countSecond
          }}秒后重试</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
     
<script>

export default {
  data() {
    return {
      formData: {
        phoneNumber: '',
        captcha: ''
      },
      rules: {
        phoneNumber: [
          { required: true, message: "please input your phone number", trigger: "blur" }
        ]
      },
      buttonShow: true,
      countSecond: '',
      timer: null,
    };
  },
  mounted() {
  },
  methods: {
    getCaptcha() {
      if (this.formData.phoneNumber === '') {
        this.$message({
          message: "phone number can't be empty",
          type: "error",
          showClose: true,
        })
        return
      }
      this.$apiFun.getOTP(this.formData.phoneNumber)
        .then((res) => {
          if (res.Code === 0) {
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.countSecond = TIME_COUNT;
              this.buttonShow = false;
              this.timer = setInterval(() => {
                if (this.countSecond > 0 && this.countSecond <= TIME_COUNT) {
                  this.countSecond--;
                } else {
                  this.buttonShow = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000)
            }
          }
        })
    },
    login() {
      if (this.formData.phoneNumber === '' || this.formData.captcha === '') {
        this.$message({
          message: "phone number and captcha can't be empty",
          type: "error",
          showClose: true,
        })
        return
      }
      this.$apiFun.login({
        phoneNumber: this.formData.phoneNumber,
        captcha: this.formData.captcha
      })
        .then((res) => {
          if (res.Code === 0) {
            let userInfo = {
              isLogin: true,
              memberId: res.Data.id,
              memberName: res.Data.name,
              token: res.Data.token,
              isAdmin: res.Data.isAdmin,
            };
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            // this.$store.state.userInfo = userInfo

            this.$message({
              message: "login success",
              type: 'success',
              showClose: true,
            });
            this.tmp_dialog.show = false
            this.$emit("callback");
          } else if (res.Code === 1) {
            this.$message({
              message: "captcha has expired",
              type: "error",
              showClose: true,
            })
          } else if (res.Code === 2) {
            this.$message({
              message: "captcha vaildation error",
              type: "error",
              showClose: true,
            })
          }
        })
    }
  },
  watch: {
  }
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 1280px;
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;

  .divider {
    height: 40px;
    margin: 0 20px;
  }

  .register-text {
    font-weight: bold;
    font-size: 24px;
  }
}

.register-form {
  width: 96%;
  margin: 0 auto;
  margin-top: 25vh;
  max-width: 550px;

  .clearfix {
    text-align: center;
  }
}
</style>